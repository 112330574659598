interface ImgUploadResp {
  success: {
    [key: string]: string;
  };
  fail: {
    [key: string]: any;
  };
}

const imgUploadUrl = `${
  process.env.REACT_APP_API_ENDPOINT || "http://localhost:8080"
}/img`;

export const uploadImg = async (imgFile: File): Promise<string | null> => {
  const fieldName = "img";
  const data = new FormData();
  data.append(fieldName, imgFile);

  try {
    const result: ImgUploadResp = await fetch(imgUploadUrl, {
      method: "POST",
      body: data,
    }).then((d) => d.json());

    if (result.success[fieldName]) {
      return result.success[fieldName];
    }
  } catch (err) {
    console.error(err);
  }

  return null;
};
