import _ from "lodash";
import { Pagination } from "react-bootstrap";

interface Props {
  onPageChange: (newOffset: number) => void;
  totalCount: number;
  offset: number;
  limit: number;
}

const OffsetBasedPagination: React.FC<Props> = ({
  onPageChange,
  totalCount,
  offset,
  limit,
}) => {
  if (offset % limit) {
    throw new Error("offset must be a multiple of limit");
  }

  if (limit === 0) {
    throw new Error("limit must be a positive number");
  }

  const PAGE_RANGE = 9;

  const curPage = offset / limit + 1;
  const lastPage = Math.max(
    Math.floor(totalCount / limit) + (totalCount % limit ? 1 : 0),
    1
  );

  const startPage = Math.max(curPage - Math.floor(PAGE_RANGE / 2), 1);
  const pages = _.range(startPage, startPage + PAGE_RANGE).reduce<number[]>(
    (prev, cur) => {
      if ((cur - 1) * limit < totalCount || cur === 1) {
        prev.push(cur);
      }
      return prev;
    },
    []
  );
  const showPrevButton = startPage > 1;
  const showNextButton = pages[pages.length - 1] * limit < totalCount;

  return (
    <Pagination>
      {showPrevButton && (
        <Pagination.Prev
          onClick={() => {
            onPageChange((Math.max(curPage - PAGE_RANGE, 1) - 1) * limit);
          }}
        />
      )}
      {pages.map((p) => {
        return (
          <Pagination.Item
            onClick={() => {
              onPageChange((p - 1) * limit);
            }}
            active={p === curPage}
            key={p}
          >
            {p}
          </Pagination.Item>
        );
      })}
      {showNextButton && (
        <Pagination.Next
          onClick={() => {
            onPageChange(
              (Math.min(curPage + PAGE_RANGE, lastPage) - 1) * limit
            );
          }}
        />
      )}
    </Pagination>
  );
};

export default OffsetBasedPagination;
