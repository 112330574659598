import { Container, Form } from "react-bootstrap";
import { TagsInput } from "react-tag-input-component";
import { QueryParamState } from ".";

interface Props {
  queryParams: QueryParamState;
  setQueryParams: React.Dispatch<React.SetStateAction<QueryParamState>>;
}

const QuizQueryForm: React.FC<Props> = ({ queryParams, setQueryParams }) => {
  return (
    <Container>
      <Form
        onSubmit={async (e) => {
          e.preventDefault();
          e.stopPropagation();
        }}
      >
        <Form.Group className="mb-3" controlId="question">
          <Form.Label>question</Form.Label>
          <Form.Control
            type="text"
            placeholder="question"
            name="question"
            value={queryParams.question || ""}
            onChange={(e) => {
              const val = e.target.value;
              setQueryParams((prev) => {
                return {
                  ...prev,
                  question: val,
                };
              });
            }}
          />
        </Form.Group>
        <Form.Group className="mb-3" controlId="hiragana">
          <Form.Label>hiragana</Form.Label>
          <Form.Control
            type="text"
            placeholder="hiragana"
            name="hiragana"
            value={queryParams.hiragana || ""}
            onChange={(e) => {
              const val = e.target.value;
              setQueryParams((prev) => {
                return {
                  ...prev,
                  hiragana: val,
                };
              });
            }}
          />
        </Form.Group>
        <Form.Group className="mb-3" controlId="level">
          <Form.Label>level</Form.Label>
          <Form.Control
            type="number"
            placeholder="level"
            name="level"
            value={queryParams.level || ""}
            min={1}
            max={5}
            onChange={(e) => {
              const val = e.target.value;
              setQueryParams((prev) => {
                return {
                  ...prev,
                  level: val ? Number(val) : undefined,
                };
              });
            }}
          />
        </Form.Group>
        <Form.Group className="mb-3" controlId="examples">
          <Form.Label>examples</Form.Label>
          <TagsInput
            value={queryParams.examples}
            onChange={(val) => {
              setQueryParams((prev) => {
                return {
                  ...prev,
                  examples: val,
                };
              });
            }}
          />
        </Form.Group>
        <Form.Group className="mb-3" controlId="answer">
          <Form.Label>answer</Form.Label>
          <Form.Control
            type="text"
            placeholder="answer"
            name="answer"
            value={queryParams.answer || ""}
            onChange={(e) => {
              const val = e.target.value;
              setQueryParams((prev) => {
                return {
                  ...prev,
                  answer: val,
                };
              });
            }}
          />
        </Form.Group>
        <Form.Group className="mb-3" controlId="answers">
          <Form.Label>answers</Form.Label>
          <TagsInput
            value={queryParams.answers}
            onChange={(val) => {
              setQueryParams((prev) => {
                return {
                  ...prev,
                  answers: val,
                };
              });
            }}
          />
        </Form.Group>
        <Form.Group className="mb-3" controlId="tags">
          <Form.Label>tags</Form.Label>
          <TagsInput
            value={queryParams.tags}
            onChange={(val) => {
              setQueryParams((prev) => {
                return {
                  ...prev,
                  tags: val,
                };
              });
            }}
          />
        </Form.Group>
      </Form>
    </Container>
  );
};

export default QuizQueryForm;
