import { useApolloClient } from "@apollo/client";
import { gql } from "__generated__";
import { QuizzesQueryInput } from "__generated__/graphql";

const HAS_QUIZ = gql(`
  query hasQuiz($input: QuizzesQueryInput) {
    quizzes(input: $input, offset: 0, limit: 1) {
      totalCount,
    }
  }
`);

const useHasQuizFn = () => {
  const client = useApolloClient();

  const hasQuiz = async (queryParams: QuizzesQueryInput) => {
    const { data } = await client.query({
      query: HAS_QUIZ,
      fetchPolicy: "no-cache",
      variables: {
        input: queryParams,
      },
    });

    return data.quizzes.totalCount !== 0;
  };

  return hasQuiz;
};

export default useHasQuizFn;
