import { Form, FormControlProps } from "react-bootstrap";

type OmitKeys = "onChange" | "type" | "name" | "accept";

interface Props extends Omit<FormControlProps, OmitKeys> {
  onChange: (imgFile?: File) => void;
  autoFocus?: boolean;
}

const ImgFormControl: React.FC<Props> = ({ onChange, ...props }) => {
  return (
    <Form.Control
      type="file"
      name="img"
      accept="image/*"
      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
        const file = e.target.files ? e.target.files[0] : undefined;

        if (!file) return;

        if (!file.type.startsWith("image/")) {
          alert("이미지 파일만 선택 가능합니다");
          e.target.value = "";
          return;
        }

        onChange(e.target.files ? e.target.files[0] : undefined);
      }}
      {...props}
    />
  );
};

export default ImgFormControl;
