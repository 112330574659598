import SignIn from "pages/auths/SignIn";
import Quizzes from "pages/Quizzes";
import AddQuiz from "pages/Quizzes/AddQuiz";
import Root from "pages/Root";
import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
  RouterProvider,
} from "react-router-dom";

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route>
      <Route path="/signin" element={<SignIn />} />
      <Route path="/" element={<Root />}>
        <Route path="quizzes">
          <Route index element={<Quizzes />} />
          <Route path="add-quiz" element={<AddQuiz />} />
        </Route>
      </Route>
    </Route>
  )
);

function App() {
  return <RouterProvider router={router} />;
}

export default App;
