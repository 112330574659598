import styled from "@emotion/styled";
import logo from "assets/logo.png";
import { Link } from "react-router-dom";

const Img = styled.img`
  width: 90px;
  cursor: pointer;
`;

const Logo: React.FC = () => {
  return (
    <Link to="/">
      <Img src={logo} alt="logo" />
    </Link>
  );
};

export default Logo;
