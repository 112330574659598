import { useState } from "react";

const ImgPreview: React.FC<{
  file?: File;
}> = ({ file }) => {
  const [src, setSrc] = useState<string>();

  if (!file) return null;

  if (!file.type.startsWith("image/")) {
    return null;
  }

  const reader = new FileReader();
  reader.readAsDataURL(file);
  reader.onload = (e) => {
    setSrc(e.target?.result as string);
  };

  return (
    <div>
      <img src={src} alt="preview" width={200} />
    </div>
  );
};

export default ImgPreview;
