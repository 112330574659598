import styled from "@emotion/styled";
import { useDeferredValue, useState } from "react";
import { Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import QuizQueryForm from "./QuizQueryForm";
import QuizzesList from "./QuizzesList";

const Wrapper = styled.div`
  display: flex;
`;

const Sidebar = styled.aside`
  width: 200px;
  flex-grow: 0;
  flex-shrink: 0;
`;

const Main = styled.main`
  flex-grow: 1;
  flex-shrink: 1;
  overflow: auto;
`;

export interface QueryParamState {
  question?: string;
  hiragana?: string;
  level?: number;
  examples: string[];
  answer?: string;
  answers: string[];
  tags: string[];
}

const Quizzes: React.FC = () => {
  const navigate = useNavigate();
  const [queryParams, setQueryParams] = useState<QueryParamState>({
    examples: [],
    answers: [],
    tags: [],
  });

  const deferredParam = useDeferredValue(queryParams);

  return (
    <Wrapper>
      <Sidebar>
        <QuizQueryForm
          queryParams={queryParams}
          setQueryParams={setQueryParams}
        />
      </Sidebar>
      <Main>
        <div>
          <Button
            onClick={() => {
              navigate("add-quiz");
            }}
          >
            퀴즈만들기
          </Button>
        </div>
        <QuizzesList queryParams={deferredParam} />
      </Main>
    </Wrapper>
  );
};

export default Quizzes;
