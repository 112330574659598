const QuizItemHeader: React.FC = () => {
  return (
    <thead>
      <tr>
        <th>#</th>
        <th>question</th>
        <th>hiragana</th>
        <th>level</th>
        <th>tags</th>
        <th>answer</th>
        <th>answers</th>
        <th>examples</th>
        <th>imgUrl</th>
        <th>actions</th>
      </tr>
    </thead>
  );
};

export default QuizItemHeader;
