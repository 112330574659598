import { useMutation } from "@apollo/client";
import { uploadImg } from "apis";
import ImgFormControl from "comps/forms/ImgFormControl";
import ImgPreview from "comps/forms/ImgPreview";
import { ADD_QUIZ } from "gqls";
import useHasQuizFn from "hooks/useHasQuizFn";
import { useState } from "react";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import { TagsInput } from "react-tag-input-component";

const AddQuiz: React.FC = () => {
  const hasQuiz = useHasQuizFn();
  const [addQuiz, { data }] = useMutation(ADD_QUIZ);
  const [examples, setExamples] = useState<string[]>([]);
  const [tags, setTags] = useState<string[]>([]);
  const [answers, setAnswers] = useState<string[]>([]);
  const [imgFile, setImgFile] = useState<File>();
  return (
    <Container>
      <Row>
        <Col>
          <Form
            onSubmit={async (e) => {
              e.preventDefault();
              const formData = new FormData(e.currentTarget);
              const question = formData.get("question");
              const hiragana = formData.get("hiragana");
              const level = formData.get("level");
              const answer = formData.get("answer");

              if (!question || !hiragana || !level || !answer) return;

              if (
                await hasQuiz({
                  question: question as string | undefined,
                })
              ) {
                alert(`question ${question} 으로 이미 등록된 퀴즈가 있습니다.`);
                return;
              }

              const imgUrl = imgFile ? await uploadImg(imgFile) : null;

              addQuiz({
                variables: {
                  quizInput: {
                    question: question as string,
                    hiragana: hiragana as string,
                    level: Number(level),
                    answer: answer as string,
                    examples,
                    tags,
                    answers,
                    imgUrl,
                  },
                },
              });
            }}
          >
            <Form.Group className="mb-3" controlId="question">
              <Form.Label>question</Form.Label>
              <Form.Control
                type="text"
                placeholder="question"
                name="question"
                required
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="hiragana">
              <Form.Label>hiragana</Form.Label>
              <Form.Control
                type="text"
                placeholder="hiragana"
                name="hiragana"
                required
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="level">
              <Form.Label>level</Form.Label>
              <Form.Control
                type="number"
                placeholder="level"
                name="level"
                required
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="examples">
              <Form.Label>examples</Form.Label>
              <TagsInput value={examples} onChange={setExamples} />
            </Form.Group>
            <Form.Group className="mb-3" controlId="answer">
              <Form.Label>answer</Form.Label>
              <Form.Control
                type="text"
                placeholder="answer"
                name="answer"
                required
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="answers">
              <Form.Label>answers</Form.Label>
              <TagsInput value={answers} onChange={setAnswers} />
            </Form.Group>
            <Form.Group className="mb-3" controlId="tags">
              <Form.Label>tags</Form.Label>
              <TagsInput value={tags} onChange={setTags} />
            </Form.Group>
            <Form.Group className="mb-3" controlId="img">
              <Form.Label>Image</Form.Label>
              <ImgPreview file={imgFile} />
              <ImgFormControl onChange={setImgFile} />
            </Form.Group>
            <Button variant="primary" type="submit">
              퀴즈만들기
            </Button>
          </Form>
        </Col>
        <Col>
          <pre>{JSON.stringify(data, null, 2)}</pre>
        </Col>
      </Row>
    </Container>
  );
};

export default AddQuiz;
