import { useMutation } from "@apollo/client";
import { DELETE_QUIZ, GET_QUIZZES, MODIFY_QUIZ } from "gqls";
import _ from "lodash";
import { useCallback } from "react";
import { Button } from "react-bootstrap";
import { GetQuizzesQuery, QuizModificationInput } from "__generated__/graphql";
import ModifiableTD from "./ModifiableTD";

type Quiz = GetQuizzesQuery["quizzes"]["quizzes"][0];

interface Props {
  quiz: Quiz;
  index?: number;
}

const QuizItem: React.FC<Props> = ({ quiz, index }) => {
  const [deleteQuiz] = useMutation(DELETE_QUIZ, {
    refetchQueries: [GET_QUIZZES],
  });
  const [modifyQuiz] = useMutation(MODIFY_QUIZ, {
    refetchQueries: [GET_QUIZZES],
  });

  const changeHandler = useCallback(
    (key: keyof QuizModificationInput) => (val: any) => {
      modifyQuiz({
        variables: {
          quiz_id: quiz.id,
          quiz_input: {
            [key]: val,
          },
        },
      });
    },
    [modifyQuiz, quiz.id]
  );

  return (
    <tr>
      <td>{index}</td>
      <ModifiableTD type="single" onChange={changeHandler("question")}>
        {quiz.question}
      </ModifiableTD>
      <ModifiableTD type="single" onChange={changeHandler("hiragana")}>
        {quiz.hiragana}
      </ModifiableTD>
      <ModifiableTD
        type="single"
        onChange={(val) => {
          val = _.toNumber(val);
          if (_.isFinite(val)) {
            modifyQuiz({
              variables: {
                quiz_id: quiz.id,
                quiz_input: {
                  level: val,
                },
              },
            });
          }
        }}
      >
        {quiz.level}
      </ModifiableTD>
      <ModifiableTD type="multi" onChange={changeHandler("tags")}>
        {quiz.tags}
      </ModifiableTD>
      <ModifiableTD type="single" onChange={changeHandler("answer")}>
        {quiz.answer}
      </ModifiableTD>
      <ModifiableTD type="multi" onChange={changeHandler("answers")}>
        {quiz.answers}
      </ModifiableTD>
      <ModifiableTD type="multi" onChange={changeHandler("examples")}>
        {quiz.examples}
      </ModifiableTD>
      <ModifiableTD type="img" onChange={changeHandler("imgUrl")}>
        {quiz.imgUrl}
      </ModifiableTD>
      <td>
        <Button
          variant="danger"
          onClick={() => {
            deleteQuiz({
              variables: {
                quiz_id: quiz.id,
              },
            });
          }}
          size="sm"
          style={{
            paddingTop: 0,
            paddingBottom: 0,
          }}
        >
          삭제
        </Button>
      </td>
    </tr>
  );
};

export default QuizItem;
