import { useQuery } from "@apollo/client";
import { gql } from "__generated__";
import { Role } from "__generated__/graphql";

const GET_USER = gql(`
  query GetUser {
    user {
      id
      role
    }
  }
`);

const useIsAdminSignedIn = () => {
  const { data, loading, error } = useQuery(GET_USER);

  const user = data?.user;

  const isAdminSignedIn = Boolean(user?.role.includes(Role.Admin));

  return {
    isAdminSignedIn,
    loading,
    error,
  };
};

export default useIsAdminSignedIn;
