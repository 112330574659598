import { useApolloClient } from "@apollo/client";
import _ from "lodash";
import { gql } from "__generated__";

const SIGN_IN_ADMIN = gql(`
  mutation signInAdmin($id: String!, $password: String!) {
    signInAdmin(id: $id, password: $password) {
      token
    }
  }
`);

const useAuth = () => {
  const client = useApolloClient();

  const signInAdmin = async (id: string, pw: string) => {
    const { data, errors } = await client.mutate({
      mutation: SIGN_IN_ADMIN,
      variables: {
        id,
        password: pw,
      },
    });

    if (errors) {
      console.warn(errors);
      return;
    }

    if (!data) {
      console.warn("no data");
      return;
    }

    const token = data.signInAdmin.token;
    localStorage.setItem("token", token);
    await client.resetStore().catch(_.noop);
  };

  const signOut = async () => {
    localStorage.removeItem("token");
    await client.resetStore().catch(_.noop);
  };

  return {
    signInAdmin,
    signOut,
  };
};

export default useAuth;
