import { Container, Nav, Navbar } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import Logo from "./Logo";
import ProfileMenu from "./ProfileMenu";

const Navs: React.FC = () => {
  const navigate = useNavigate();
  return (
    <Container fluid>
      <Navbar>
        <Navbar.Brand>
          <Logo />
        </Navbar.Brand>
        <Nav className="me-auto">
          <Nav.Link onClick={() => navigate("/quizzes")}>quizzes</Nav.Link>
        </Nav>
        <ProfileMenu />
      </Navbar>
    </Container>
  );
};

export default Navs;
