import { uploadImg } from "apis";
import ImgFormControl from "comps/forms/ImgFormControl";
import { PropsWithChildren, useEffect, useId, useRef, useState } from "react";
import { Button, Form } from "react-bootstrap";
import { TagsInput } from "react-tag-input-component";

const MultiValueInput: React.FC<{
  initialValue: string[];
  onEnd: (val: string[]) => void;
}> = ({ initialValue, onEnd }) => {
  const formRef = useRef<HTMLFormElement>(null);
  const [val, setVal] = useState<string[]>(initialValue);

  useEffect(() => {
    if (formRef.current) {
      const form = formRef.current;
      const input = form.querySelector(".rti--input");
      if (input) {
        (input as HTMLInputElement).focus();
      }
    }
  }, []);

  return (
    <Form
      onSubmit={(e) => {
        e.preventDefault();
        e.stopPropagation();
        onEnd(val);
      }}
      ref={formRef}
    >
      <TagsInput
        value={val}
        onChange={(val) => setVal(val)}
        onBlur={() => {
          onEnd(initialValue);
        }}
        onKeyUp={(e) => {
          if (e.key === "Escape") {
            onEnd(initialValue);
          }
        }}
      />
    </Form>
  );
};

const SingleValueInput: React.FC<{
  initialValue: string;
  onEnd: (val: string) => void;
}> = ({ initialValue, onEnd }) => {
  const [val, setVal] = useState<string>(initialValue);

  const id = useId();

  return (
    <Form
      onSubmit={(e) => {
        e.preventDefault();
        e.stopPropagation();
        onEnd(val);
      }}
    >
      <Form.Group className="mb-3" controlId={id}>
        <Form.Control
          type="text"
          value={val}
          onChange={(e) => {
            setVal(e.target.value);
          }}
          onBlur={() => {
            onEnd(val);
          }}
          autoFocus
        />
      </Form.Group>
    </Form>
  );
};

const ImgInput: React.FC<{
  onEnd: (val?: string | null) => void;
}> = ({ onEnd }) => {
  const [imgFile, setImgFile] = useState<File>();

  const id = useId();

  const handleFile = async () => {
    const imgUrl = imgFile ? await uploadImg(imgFile) : null;
    onEnd(imgUrl);
  };

  return (
    <Form
      onSubmit={(e) => {
        e.preventDefault();
        e.stopPropagation();
        handleFile();
      }}
    >
      <Form.Group className="mb-3" controlId={id}>
        <ImgFormControl onChange={setImgFile} />
      </Form.Group>
      <Button variant="primary" size="sm" type="submit">
        확인
      </Button>
      <Button
        variant="secondary"
        size="sm"
        type="button"
        onClick={() => {
          onEnd(null);
        }}
      >
        취소
      </Button>
    </Form>
  );
};

interface Props {
  type: "single" | "multi" | "img";
  onChange?: (value: any) => void;
}

const ModifiableTD: React.FC<PropsWithChildren<Props>> = ({
  children,
  type,
  onChange,
}) => {
  const [isModifying, setIsModifying] = useState(false);

  let child = null;

  const handleEnd = (val: any) => {
    if (onChange) {
      onChange(val);
    }
    setIsModifying(false);
  };

  if (type === "single") {
    if (isModifying) {
      child = (
        <SingleValueInput initialValue={children as string} onEnd={handleEnd} />
      );
    } else {
      child = children;
    }
  } else if (type === "multi") {
    if (isModifying) {
      child = (
        <MultiValueInput
          initialValue={children as string[]}
          onEnd={handleEnd}
        />
      );
    } else {
      child = (children as string[]).join(", ");
    }
  } else if (type === "img") {
    if (isModifying) {
      child = <ImgInput onEnd={handleEnd} />;
    } else {
      child = children ? (
        <img src={children as string} alt="preview" width={150} />
      ) : null;
    }
  }

  return (
    <td
      onDoubleClick={() => {
        if (!isModifying) {
          setIsModifying(true);
        }
      }}
    >
      {child}
    </td>
  );
};

export default ModifiableTD;
