import { useQuery } from "@apollo/client";
import DataFetchError from "comps/errors/DataFetchError";
import LoadingSpinner from "comps/loading/LoadingSpinner";
import OffsetBasedPagination from "comps/pagination/OffsetBasedPagination";
import { GET_QUIZZES } from "gqls";
import { useEffect, useState } from "react";
import { Container, Table } from "react-bootstrap";
import { useDebounce } from "usehooks-ts";
import { Quiz } from "__generated__/graphql";
import { QueryParamState } from "..";
import QuizItem from "./QuizItem";
import QuizItemHeader from "./QuizItemHeader";

interface Props {
  queryParams: QueryParamState;
}

interface IndexedQuiz extends Quiz {
  index: number;
}

const withData =
  (Comp: typeof QuizzesList) =>
  ({ queryParams }: Props) => {
    queryParams = useDebounce(queryParams, 200);
    const [totalCount, setTotalCount] = useState(0);
    const [quizzes, setQuizzes] = useState<Array<IndexedQuiz>>([]);
    const [offset, setOffset] = useState(0);

    const LIMIT = 20;

    useEffect(() => {
      setOffset(0);
    }, [queryParams]);

    const { error, loading } = useQuery(GET_QUIZZES, {
      fetchPolicy: "network-only",
      variables: {
        input: queryParams,
        offset,
        limit: LIMIT,
      },
      onCompleted(data) {
        const quizzes = data.quizzes.quizzes.map((cur, i) => {
          return {
            index: offset + i + 1,
            ...cur,
          };
        });
        setQuizzes(quizzes);
        setTotalCount(data.quizzes.totalCount);
      },
    });

    if (loading) {
      return <LoadingSpinner />;
    }

    if (error) {
      return <DataFetchError />;
    }

    return (
      <Comp
        quizzes={quizzes}
        totalCount={totalCount}
        offset={offset}
        limit={LIMIT}
        onOffsetChange={setOffset}
      />
    );
  };

interface ViewProps {
  quizzes: Array<IndexedQuiz>;
  totalCount: number;
  offset: number;
  limit: number;
  onOffsetChange: (newOffset: number) => void;
}

const QuizzesList: React.FC<ViewProps> = ({
  quizzes,
  totalCount,
  offset,
  limit,
  onOffsetChange,
}) => {
  return (
    <Container>
      <Table striped bordered hover size="sm">
        <QuizItemHeader />
        <tbody>
          {quizzes.map((quiz) => {
            return <QuizItem quiz={quiz} key={quiz.id} index={quiz.index} />;
          })}
        </tbody>
      </Table>
      <OffsetBasedPagination
        offset={offset}
        limit={limit}
        totalCount={totalCount}
        onPageChange={onOffsetChange}
      />
    </Container>
  );
};

export default withData(QuizzesList);
