import styled from "@emotion/styled";
import Navs from "comps/Navs";
import useIsAdminSignedIn from "hooks/useIsAdminSignedIn";
import { Outlet, useNavigate } from "react-router-dom";

const Wrapper = styled.div``;

const Root: React.FC = () => {
  const navigate = useNavigate();
  const { isAdminSignedIn, loading, error } = useIsAdminSignedIn();

  if (loading || error) return null;
  if (!isAdminSignedIn) {
    navigate("/signin", {
      replace: true,
    });
  }

  return (
    <Wrapper>
      <Navs />
      <Outlet />
    </Wrapper>
  );
};

export default Root;
