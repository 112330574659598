import { gql } from "__generated__";

export const GET_QUIZZES = gql(`
  query GetQuizzes($input: QuizzesQueryInput, $offset: Int, $limit: Int) {
    quizzes(input: $input, offset: $offset, limit: $limit) {
      totalCount,
      quizzes {
        id,
        tags,
        level,
        question,
        hiragana,
        examples,
        answer,
        answers,
        imgUrl,
      }
    }
  }
`);

export const ADD_QUIZ = gql(`
  mutation addQuiz($quizInput: QuizCreationInput!) {
    addQuiz(quizInput: $quizInput) {
      tags
      level
      question
      hiragana
      examples
      answer
      answers
      imgUrl
    }
  }
`);

export const MODIFY_QUIZ = gql(`
  mutation modifyQuiz($quiz_id: String!, $quiz_input: QuizModificationInput!) {
    modifyQuiz(quiz_id: $quiz_id, quiz_input: $quiz_input) {
      id,
      tags,
      level,
      question,
      hiragana,
      examples,
      answer,
      answers,
      imgUrl
    }
  }
`);

export const DELETE_QUIZ = gql(`
  mutation deleteQuiz($quiz_id: String!) {
    deleteQuiz(quiz_id: $quiz_id)
  }
`);
